/**
 * @generated SignedSource<<a1658503387430a9da695242ba00fd0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubscriberUploadDialog_SettingsUI_Query$variables = {
  companyId: string;
};
export type SubscriberUploadDialog_SettingsUI_Query$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"CompanyRegionSelectorSettingsUIFragment_company">;
  } | null;
};
export type SubscriberUploadDialog_SettingsUI_Query$rawResponse = {
  readonly node: {
    readonly __typename: "Company";
    readonly id: string;
    readonly regions: ReadonlyArray<{
      readonly countryCode: string;
      readonly id: string;
      readonly languageTag: string;
      readonly localizedSettings: ReadonlyArray<{
        readonly languageTag: string;
      }>;
    }> | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type SubscriberUploadDialog_SettingsUI_Query = {
  rawResponse: SubscriberUploadDialog_SettingsUI_Query$rawResponse;
  response: SubscriberUploadDialog_SettingsUI_Query$data;
  variables: SubscriberUploadDialog_SettingsUI_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "languageTag",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubscriberUploadDialog_SettingsUI_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompanyRegionSelectorSettingsUIFragment_company"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubscriberUploadDialog_SettingsUI_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Region",
                "kind": "LinkedField",
                "name": "regions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "countryCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LocalizedSettings",
                    "kind": "LinkedField",
                    "name": "localizedSettings",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb5ba27c46e6e5581f5d007d36380b4d",
    "id": null,
    "metadata": {},
    "name": "SubscriberUploadDialog_SettingsUI_Query",
    "operationKind": "query",
    "text": "query SubscriberUploadDialog_SettingsUI_Query(\n  $companyId: ID!\n) {\n  node(id: $companyId) {\n    __typename\n    ...CompanyRegionSelectorSettingsUIFragment_company\n    id\n  }\n}\n\nfragment CompanyRegionListItemFragment_region on Region {\n  id\n  countryCode\n  localizedSettings {\n    languageTag\n  }\n  languageTag\n}\n\nfragment CompanyRegionSelectorSettingsUIFragment_company on Company {\n  id\n  regions {\n    id\n    ...CompanyRegionListItemFragment_region\n  }\n}\n"
  }
};
})();

(node as any).hash = "08a2b075eb1143f5abae6363ea39e4b9";

export default node;
